.textContent {
  max-width: 40rem;
  margin: auto;
  margin-bottom: 10rem;
  padding: 1rem 1rem 0 1rem;
}

.textContent img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 3rem auto;
}

.textContent p {
  margin-bottom: 1rem;
}
