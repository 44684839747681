.galleryContainer {
  margin-top: 1rem;
}

@media (max-width: 767.98px) {
  .labelList {
    display: none;
  }
}

.select {
  max-width: 15rem;
}

@media (max-width: 575.98px) {
  .form {
    display: block;
  }
}

.form label {
  min-width: 4rem;
}
