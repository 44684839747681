.labelList {
  background-color: #fafafa;
  padding: 20px;
  height: 100%;
  min-height: 100vh;
}

.labelList button {
  padding: 0;
}

.labels button {
  font-size: 0.75rem;
  padding: 0 0.2rem;
}

.labels :global(button.btn) {
  text-align: left;
}
