.imageCard {
  width: 20rem;
}

@media (min-width: 576px) {
  .textCard {
    width: 30rem;
  }

  .videoCard {
    width: 30rem;
  }
}

.summary {
  font-size: .9rem;
  line-height: 1.2;
}

.collapse {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.readMore {
  font-size: 0.75rem;
}

.givenLabel {
  font-size: 1.25rem;
}

.givenLabelLabel {
  padding-bottom: 1px;
  border-bottom: 3px solid red;
}

.smallText {
  color: #555;
  font-size: 0.6rem;
}
